<template>
  <div class="order_container">
    <el-row class="order_header">
      <el-col :span="4">{{ name }}</el-col>
      <el-col :span="16" :offset="4" class="step">
        1.选择科室&nbsp;&nbsp; 2.选择医生&nbsp;&nbsp; 3.选择时段&nbsp;&nbsp;
        <span class="active">4.确认挂号</span>
      </el-col>
    </el-row>
    <Count></Count>
    <el-row class="order_title">
      <h1>温馨提示：确认挂号信息后，请点击下方确认挂号按钮</h1>
    </el-row>
    <div class="order_main">
      <div class="order_info">
        <h1>挂号信息</h1>
        <p>
          诊疗卡号：
          <span>{{ query.patCardNo }}</span>
        </p>
        <p>
          医生姓名：
          <span>{{ query.YSMC || query.MC }}</span>
        </p>
        <p>
          就诊日期：
          <span>{{ query.YYRQ }}</span>
        </p>
        <p>
          就诊时段：
          <span>{{ query.RQ || query.KZSJ }}</span>
        </p>
        <div class="register">
          <el-button type="success" round class="buttM" size="medium" @click="lockRegs">挂号</el-button>
        </div>
      </div>
    </div>
    <el-row class="order_tip">
      <el-col :span="24">
        <span>{{ patName }}</span
        >&nbsp;&nbsp;您好&nbsp;&nbsp;欢迎使用自助终端，请您使用结束后退出首页&nbsp;&nbsp;如非本人，请退出首页，谢谢！
      </el-col>
    </el-row>
    <el-button type="primary" @click.native="back" class="back">返回</el-button>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost, formPost } from '@/baseAPI'
import { lockRegToday, lockReg } from '@/service/register'
import dayjs from 'dayjs'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      deptName: '',
      name: '',
      patName: '',
      query: {},
      orderNum: ''
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.deptName = this.$route.query.deptName
    this.query = this.$route.query
    this.query.scheduleDate = dayjs(new Date()).format('YYYY')
  },
  methods: {
    back() {
      this.$router.back()
    },
    home() {
      this.$router.push('/home')
    },
    async lockRegs() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if (this.$route.query.name == '当天挂号') {
        let data = {
          RQ: this.query.RQ,
          CARDNO: this.query.patCardNo,
          KSBM: this.query.KSBM,
          YSBM: this.query.YSBM
        }
        const res = await lockRegToday(data)
        console.log(res)
        loading.close()
        if (res.data.code == 0) {
          this.$message({
            message: res.data.data.MSG,
            type: 'success'
          })
          this.$router.push({
            path: '/qrCodePay',
            query: {
              HYID: res.data.data.RESULT.HYID,
              ZJJE: res.data.data.RESULT.ZJJE,
              KSBM: this.query.KSBM,
              YSBM: this.query.YSBM,
              payType: '1',
              patName: this.$route.query.patName,
              patCardNo: this.$route.query.patCardNo,
              patCardType: this.$route.query.patCardType
            }
          })
        } else {
          this.$message.error(res.data.data.MSG)
        }
      } else {
        let data = {
          KSBM: this.query.KSBMs,
          YSBM: this.query.YSBMs,
          YYRQ: this.query.YYRQ,
          CARDNO: this.query.patCardNo,
          SJD: this.query.SJD
        }
        const res = await lockReg(data)
        console.log(res)
        loading.close()
        if (res.data.code == 0) {
          this.$message({
            message: res.data.data.MSG,
            type: 'success'
          })
          this.$router.push({
            path: '/qrCodePay',
            query: {
              HYID: res.data.data.RESULT.HYID,
              ZJJE: res.data.data.RESULT.ZJJE,
              payType: '2',
              patName: this.$route.query.patName,
              patCardNo: this.$route.query.patCardNo,
              patCardType: this.$route.query.patCardType
            }
          })
        } else {
          this.$message.error(res.data.data.MSG)
        }
      }
    },
    patRegReport(appointmentId, query) {
      let _this = this
      const params = {
        appointmentId
      }
      Promise.all([_this.$api.patRegReport(params)]).then(data => {
        if (data[0].code == 0) {
          query.isSucc = '1'
          this.$router.push({
            path: '/succeed',
            query
          })
        } else {
          this.$router.push({
            path: '/succeed',
            query
          })
        }
      })
    },
    unifiedOrder() {
      let _this = this
      const params = {
        orderNum: this.orderNum,
        recordType: 1,
        regFee: this.query.regFee
      }
      Promise.all([_this.$api.unifiedOrder(params)]).then(data => {
        this.$router.push({
          path: '/mode',
          query: data[0].qrCode
        })
      })
    },
    apiAddress() {}
  }
}
</script>
<style>
.order_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.order_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 45px;
  font-weight: 700;
  line-height: 100px;
  color: #009944;
  letter-spacing: 20px;
  padding-left: 30px;
}

.order_header .step {
  font-size: 35px;
  font-weight: 400;
  padding: 0;
  letter-spacing: 0;
  text-align: right;
  padding-right: 30px;
}

.order_header .step .active {
  color: blue;
}

.order_title h1 {
  margin: 4% auto;
  font-weight: bold;
  color: rgba(255, 0, 0, 0.77);
  text-align: center;
  letter-spacing: 10px;
  font-size: 70px;
}

.order_main {
  position: relative;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 20px;
  height: 36%;
  width: 25%;
}

.order_main h1 {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  color: red;
  letter-spacing: 10px;
  font-size: 60px;
  font-weight: bold;
}

.order_main .order_info {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.order_main p {
  margin-top: 40px;
  font-size: 50px;
  font-weight: bold;
}

.register {
  margin: 0 auto;
  text-align: center;
}
.buttM {
  margin-top: 40px;
  height: 50%;
  width: 70%;
  text-align: center;
  font-size: 40px;
}
.order_tip {
  position: fixed !important;
  bottom: 40px;
  left: 0;
  z-index: 1;
  padding-left: 30px;
  font-size: 50px;
  color: red;
}

.order_tip span {
  color: blue;
}

.order_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 270px;
  font-size: 35px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 35px !important;
}
</style>
